import React, { Component } from 'react';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import {Form, Row, Col, Button, Container} from "react-bootstrap";
import {API_HEAD, URL_API} from "./index";
import {Helmet} from "react-helmet";

class Contact extends Component{
    constructor(props) {
        super (props);
        this.state = {
            page: null,
        }
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        const query = `{
            entry(slug: "nous-contacter") {
                id
                title
                slug
                ... on pages_pages_Entry {
                    page {
                        ... on MatrixBlockInterface {
                            ... on page_titre_BlockType {
                                texte
                                image {
                                    url
                                    title
                                }
                                seo {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
        }`;

        fetch(URL_API(), API_HEAD(query))
            .then(response => response.json())
            .then(data => {
                this.setState({
                    page: data.data.entry
                });
            })
        ;
    }

    render() {
        const page = this.state.page;
        return(
            <>
                <Helmet>
                    <title>{page && page.page ? page.page[0].seo.title : "Contactez-nous - Terra Nostra"}</title>
                    {page && page.page && page.page[0].seo.description ?
                        <meta name="description" content={page.page[0].seo.description}/> : ''
                    }
                    <link rel="canonical" href={"https://" + window.location.host + '/contact' }/>
                    <link rel="preconnect" href={URL_API()}/>
                </Helmet>
                <div className="accueil">
                    <Header/>
                    <div className="article-header bg-basique">
                        <Container className="align-items-end">
                            <h1 className="text-white mt-1">{page ? page.title : "Contactez-nous"}</h1>
                        </Container>
                    </div>

                    <Container className="mb-5 mt-5">
                        <Row>
                            <Col xs={10} lg={8}>
                                <Form>
                                    <div className="text-left">
                                        <select className="custom-select mb-3">

                                        </select>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" className="form-control pt-3 pb-3"
                                                   placeholder="Adresse email"/>
                                        </div>
                                        <Form.Group>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows="5" placeholder="Message"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check type="checkbox"
                                                        label="J'accepte les conditions générales d'utilisations"/>
                                        </Form.Group>
                                        <Button className="bg-blue text-white border-0 text-center"
                                                type="submit">
                                            Valider
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                            {/*<Col xs={10} lg={4}>*/}
                            {/*    <Newsletter/>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>

                    <Footer/>
                </div>
            </>
        )
    }
}

export default Contact