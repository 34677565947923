import React, { Component } from 'react';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import './scss/pages/_about.scss';
import {Container, Row, Col, Image} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {API_HEAD, URL_API} from "./index";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import LazyLoad from "react-lazy-load";
import ArticleLoading from "./components/ArticleLoading";

class Apropos extends Component{

    constructor(props) {
        super (props);
        this.state = {
            page: null,
        }
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        const query = `{
            entry(slug: "qui-sommes-nous") {
                id
                title
                slug
                ... on pages_pages_Entry {
                    page {
                        ... on MatrixBlockInterface {
                            ... on page_titre_BlockType {
                                texte
                                image {
                                    url
                                    title
                                }
                                seo {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
        }`;

        fetch(URL_API(), API_HEAD(query))
            .then(response => response.json())
            .then(data => {
                this.setState({
                    page: data.data.entry
                });
            })
        ;
    }


    render() {
        const page = this.state.page;
        return(

            <>
                {page && page.page[0] ?
                    <Helmet>
                        <script src="https://tarteaucitron.io/load.js?domain=blog.terra-nostra.xyz&uuid=54ba2930f284661c4346f50c813d2b6b062b092b"/>
                        <title>{page.page[0].seo.title}</title>
                        {page.page[0].seo.description ?
                            <meta name="description" content={page.page[0].seo.description}/> : ''}
                        <link rel="canonical" href={"https://" + window.location.host + '/' + page.slug}/>
                        <link rel="preconnect" href={URL_API()}/>
                        <meta property="og:url" content={`https://${window.location.host}/${page.slug}`}/>
                        <meta property="og:type" content="article"/>
                        <meta property="og:title" content={page.page[0].seo.title}/>
                        <meta property="og:description" content={page.page[0].seo.description}/>
                        <meta property="og:image" content={page.page[0].image[0].url}/>
                    </Helmet> : ''
                }
                <div className="accueil">
                    <Header/>
                    {page && page.page[0] ?
                        <>
                            <div className="article-header bg-basique">
                                <Container className="align-items-end">
                                    <h1 className="text-white mt-1">{page.title}</h1>
                                </Container>
                            </div>
                            <Container className="mb-5 mt-5">
                                <Row>
                                    <Col xs={12} lg={8}>

                                        {parse (DOMPurify.sanitize (page.page[0].texte))}

                                        <LazyLoad>
                                            <Image src={page.page[0].image[0].url} className={"img-fluid"}
                                                   alt={page.page[0].image[0].title}/>
                                        </LazyLoad>

                                    </Col>

                                    {/*<Col lg={4}>*/}
                                    {/*    <Newsletter/>*/}
                                    {/*</Col>*/}
                                </Row>
                            </Container>
                        </> : <div>
                            <ArticleLoading banner="true"/>
                            <Container className="mb-5 mt-5">
                                <Row>
                                    <Col xs={12} lg={8}>
                                        <ArticleLoading/>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }
                    <Footer/>
                </div>
            </>
        )
    }
}

export default Apropos;