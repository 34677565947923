import ContentLoader from "react-content-loader";
import React from "react";


class ArticleLoading extends React.Component {

    render() {
       let loading = [];

       if(this.props.banner) {
           loading.push(
               <div className="mb-4" key={1}>
                   <ContentLoader viewBox="0 0 520 100">
                       <rect x="0" y="0" width="520" height="520"/>
                   </ContentLoader>
               </div>
           );
       } else {
           loading.push(
               <div className="pb-3" key={2}>
                   <ContentLoader viewBox="0 0 340 84">
                       <rect x="0" y="0" width="340" height="8" rx="3" ry="3"/>
                       <rect x="0" y="12" width="320" height="8" rx="3" ry="3"/>
                       <rect x="0" y="24" width="340" height="8" rx="3" ry="3"/>
                       <rect x="0" y="36" width="330" height="8" rx="3" ry="3"/>
                       <rect x="0" y="48" width="300" height="8" rx="3" ry="3"/>
                   </ContentLoader>

                   <ContentLoader viewBox="0 0 340 250">
                       <rect x="0" y="0" rx="0" ry="0" width="180" height="180" />
                       <rect x="200" y="0" width="140" height="8" rx="3" ry="3"/>
                       <rect x="200" y="12" width="140" height="8" rx="3" ry="3"/>
                       <rect x="200" y="24" width="140" height="8" rx="3" ry="3"/>
                       <rect x="200" y="72" width="140" height="8" rx="3" ry="3"/>
                       <rect x="200" y="84" width="120" height="8" rx="3" ry="3"/>
                       <rect x="0" y="200" width="120" height="8" rx="3" ry="3"/>
                       <rect x="0" y="212" width="340" height="8" rx="3" ry="3"/>
                       <rect x="0" y="224" width="320" height="8" rx="3" ry="3"/>
                   </ContentLoader>

                   <ContentLoader viewBox="0 0 340 100">
                       <rect x="0" y="0" width="340" height="8" rx="3" ry="3"/>
                       <rect x="0" y="12" width="320" height="8" rx="3" ry="3"/>
                       <rect x="0" y="24" width="320" height="8" rx="3" ry="3"/>
                       <rect x="0" y="36" width="310" height="8" rx="3" ry="3"/>
                       <rect x="0" y="48" width="330" height="8" rx="3" ry="3"/>
                       <rect x="0" y="60" width="340" height="8" rx="3" ry="3"/>
                       <rect x="0" y="72" width="320" height="8" rx="3" ry="3"/>
                       <rect x="0" y="84" width="320" height="8" rx="3" ry="3"/>
                   </ContentLoader>

                   <ContentLoader viewBox="0 0 340 200">
                       <rect x="0" y="0" width="340" height="200"/>
                   </ContentLoader>
               </div>
           );
       }

        return (
            <>
                {loading}
            </>
        )
    }
}

export default ArticleLoading;