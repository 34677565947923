import React, { Component } from 'react';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import './scss/pages/_about.scss';
import {Container, Row, Col} from "react-bootstrap";
import {Helmet} from "react-helmet";


class MentionsLegales extends Component{

    render() {

        return(
            <>
                <Helmet>
                    <script src="https://tarteaucitron.io/load.js?domain=blog.terra-nostra.xyz&uuid=54ba2930f284661c4346f50c813d2b6b062b092b"/>
                    <title>Blog Terra Nostra : mentions légales</title>
                    <link rel="canonical" href={"https://" + window.location.host + "/mentions-legales"}/>
                    <meta property="og:url"                content="https://blog.terra-nostra.xyz/mentions-legales"/>
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content="Blog Terra Nostra : mentions légales" />
                    <meta property="og:description"        content="Consultez les mentions légales du blog Terra Nostra!" />
                    <meta property="og:image"              content="https://blog.terra-nostra.xyz//static/media/plage.5770bbde.png" />
                </Helmet>
                <div className="accueil">
                    <Header/>
                    <div className="article-header bg-basique">
                        <Container className="align-items-end">
                            <h1 className="text-white mt-1">Mentions Légales</h1>
                        </Container>
                    </div>

                    <Container className="mb-5 mt-5">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h3>1. Identification du Site</h3>

                                <p>
                                    Nom du site : <strong>Blog Terra Nostra</strong><br />
                                    URL complète du site : https://blog.terra-nostra.xyz
                                </p>

                                <br/>

                                <h3>2. Identification du propriétaire du site</h3>

                                <p>Nom ou raison sociale: <a href="https://www.mare-nostrum.eu/" style={{color: "#000000"}}><strong>Mare Nostrum </strong></a><br />
                                    Adresse du siège social: 9 avenue de Constantine 38100 Grenoble<br />
                                    Contact mail : bonjour@mare-nostrum.eu<br />
                                    Contact téléphone: 04 38 12 33 51<br />
                                </p>

                                <h3>3. Insertion des numéros d'identification</h3>

                                <p>Numéro et ville d'inscription au RCS: RCS de Grenoble - n°479 802 365<br />
                                    Capital social de la société : 450 500,00 €<br />
                                    Numéro d'assujettissement à la TVA (CGI, art. 286 ter) : FR 51 479 802 365</p>

                                <br/>

                                <h3>4. Publication du site</h3>

                                <p>Nom du directeur de publication: Céline Anselme<br />
                                    Nom du responsable de rédaction: Céline Anselme<br />
                                    Webmaster du site: Herman Yesso</p>

                                <br/>

                                <h3>5. Concepteur de site</h3>

                                <p>Nom ou raison sociale: <strong>Mare Nostrum</strong><br />
                                    Adresse du siège social: 9 avenue de Constantine 38100 Grenoble<br />
                                    Contact mail et/ou téléphone: 04 38 12 33 51<br />
                                    Mentions des principaux auteurs de contenus du site: Céline Anselme, Herman Yesso<br />
                                    Le prestataire de développement : société <strong>PROSPECTIVA</strong></p>

                                <br/>

                                <h3>6. Hébergeur du site</h3>

                                <p>Nom de l'hébergeur: <a href="https://www.enigmatic.fr/" style={{color: "#000000"}}><strong>Enigmatic</strong></a><br />
                                    Sa raison sociale : Enigmatic<br />
                                    Son adresse postale : 9 avenue de Constantine, 38100 Grenoble<br />
                                    Son numéro de téléphone: 04 58 00 31 81<br />
                                    Ou, à défaut, une adresse mail : support@enigmatic.fr<br />
                                </p>

                                <h3>7. Données personnelles</h3>

                                <p>Coordonnées de contact (mail ou téléphone) pour faire valoir ses droits : 04 38 12 33 50 - servicedsi@mare-nostrum.eu</p>
                            </Col>
                        </Row>
                    </Container>

                    <Footer/>
                </div>
            </>
        )
    }
}

export default MentionsLegales