import React, {Component} from 'react';
import LogoMaia from "../img/logo-entete-tn.svg";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Nav, Image} from "react-bootstrap";
import RechercheModal from "../components/RechercheModal";
import {faFacebook, faLinkedin} from "@fortawesome/free-brands-svg-icons";

class Header extends Component {

    state = {
        menu: false,
    }

    setMenu() {
        this.setState(
            {
                menu: !this.state.menu
            }
        )
    }

    getReseauxSociaux($textWhite = false) {
        return (
            <>
                <li className="list-inline-item"><a rel="noreferrer" className={ $textWhite ? "text-white" : "text-dark"} href="https://www.facebook.com/Terra-Nostra-339022159948969" target="_blank"><FontAwesomeIcon className="fa-2x" icon={faFacebook}/></a></li>
                <li className="list-inline-item"><a rel="noreferrer" className={ $textWhite ? "text-white" : "text-dark"} href="https://www.linkedin.com/company/terra-nostra-emploi" target="_blank"><FontAwesomeIcon className="fa-2x" icon={faLinkedin}/></a></li>
            </>
        );
    }

    render() {

        return (
            <header className="sticky-top">
                <nav className="p-3 logoheader justify-content-between d-flex">
                    <div className="p-lg-3 align-self-center">
                        <span className="d-lg-none" onClick={() => this.setMenu()}><i className={this.state.menu ? "fas fa-times fa-2x" : "fas fa-bars fa-2x"}/></span>
                    </div>
                    <div className="align-self-center mr-1 ml-1">
                        <Link to="/">
                            <Image
                                src={LogoMaia}
                                alt="LE BLOG TERRA NOSTRA"
                                id="logo"
                                className="img-fluid mx-auto d-block"
                                height="100"
                                width="400"
                            />
                        </Link>
                    </div>
                    <div className="align-self-center">
                        <ol className="list-inline d-none d-lg-block">
                            {this.getReseauxSociaux()}
                        </ol>
                    </div>
                </nav>

                <Nav
                    className={this.state.menu ? "justify-content-center bg-blue d-block d-lg-flex pb-2" : "justify-content-center bg-blue d-none d-lg-flex pb-2"}
                    activeKey="/home"
                >
                    <Nav.Item>
                        <Link className="nav-link" to="/">LE BLOG</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link className="nav-link" to="/qui-sommes-nous">QUI SOMMES NOUS</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <a href="mailto:bonjour@terra-nostra.xyz" className="nav-link">CONTACT</a>
                    </Nav.Item>
                    <Nav.Item className="pl-3 pointer">
                        <RechercheModal/>
                    </Nav.Item>
                    <Nav.Item className="d-lg-none">
                        <ol className="list-inline text-center">
                            {this.getReseauxSociaux(true)}
                        </ol>
                    </Nav.Item>
                </Nav>
            </header>
        )
    }
}

export default Header;