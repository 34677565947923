import React, {Component} from 'react';
import './scss/pages/_home.scss';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import GetQueryArticles from "./query/Articles";
import MinArticle from "./components/MinArticle";
import {Container, Row, Col} from "react-bootstrap";
import {API_HEAD, URL_API} from "./index";
import moment from "moment";
import {Helmet} from "react-helmet";
import Loading from "./components/Loading";
import AfficherPlus from "./components/AfficherPlus";
import Newsletter from "./components/Newsletter";

class Accueil extends Component {

    constructor(props) {
        super (props);
        this.state = {
            articles: [],
            firstArticles: [],
            othersArticles: [],
            countArticles: 0,
            categories: [],
            tags: [],
            index: 1,
            filterTags: null,
            filterCategories: null
        };

        this.handleChangeTags = this.handleChangeTags.bind(this);
        this.handleChangeCategories = this.handleChangeCategories.bind(this);
    }

    handleChangeTags(event) {
        this.setState({
            filterTags: event.target.value,
        });
    }

    handleChangeCategories(event) {
        this.setState({
            filterCategories: event.target.value,
        });
    }

    getAllCategoriesQuery() {
        return `
        {
            categories
                {
                    id
                    title
                    groupId
                }
        }`
    }

    getAfficherPlus(articles){
        this.setState({
            articles: this.state.articles.concat(articles),
            index: this.state.index + 1
        });
    }

    async getFirstArticles() {
        return fetch(URL_API(), API_HEAD(GetQueryArticles('limit: 3')))
            .then(response => response.json())
            .then(data => data.data.entries)
        ;
    }

    async getArticles() {
        return fetch(URL_API(), API_HEAD(GetQueryArticles('offset:3, limit: 6')))
            .then(response => response.json())
            .then(data => data.data)
        ;
    }

    async getAllCategories() {
        return fetch(URL_API(), API_HEAD(this.getAllCategoriesQuery()))
            .then(response => response.json())
            .then(data => data.data.categories)
        ;
    }

    async componentDidMount() {
        const firstArticles = await this.getFirstArticles();
        const articles = await this.getArticles();
        const allCategories = await this.getAllCategories();
        const categories =[];
        const tags = [];

        // eslint-disable-next-line array-callback-return
        allCategories.map(category => {
            // eslint-disable-next-line default-case
            switch (category.groupId) {
                case 1:
                    categories.push(category);
                    break;
                case 2:
                    tags.push(category);
                    break;
            }
        });

        this.setState({
            firstArticles: firstArticles.slice(0,1),
            othersArticles:firstArticles.slice(1,3),
            articles: articles.entries,
            categories: categories,
            tags: tags,
            countArticles: articles.entryCount
        });
    }

    render() {
        const resArticle = this.state.articles.map (({blog, id, dateCreated, title, slug}) =>
            <MinArticle
                article={blog[0]}
                titre={title}
                slug={slug}
                date={moment (dateCreated).format ('DD/MM/YYYY')}
                id={id}
                key={id}
            />
        );

        const tabArticles = this.state.othersArticles.map(({blog, id, dateCreated, title, slug}, index) =>
            <MinArticle
                article={blog[0]}
                titre={title}
                slug={slug}
                date={moment (dateCreated).format ('DD/MM/YYYY')}
                id={id}
                key={id}
                header={true}
            />
        );

        const firstArticle = this.state.firstArticles.map (({blog, id, dateCreated, title, slug}) =>
            <MinArticle
                article={blog[0]}
                titre={title}
                slug={slug}
                date={moment (dateCreated).format ('DD/MM/YYYY')}
                id={id}
                key={id}
                grand={true}
                header={true}
            />
        );

        return (
            <>
                <Helmet>
                    <script src="https://tarteaucitron.io/load.js?domain=blog.terra-nostra.xyz&uuid=54ba2930f284661c4346f50c813d2b6b062b092b"/>
                    <title>Terra Nostra votre partenaire pour vous accompagner sur les thématiques Emploi, recrutement, formation, travail temporaire, portage salarial & mobilité professionnelle et Conseil & entreprenariat, des professionnels à votre écoute.</title>
                    <meta name="description" content="Quelle que soit votre question ou simplement une envie d'information ce blog traite de l'ensemble des thématiques RH et des sujets environnants carrière, formation, vie en entreprise, mobilité et bien plus encore."/>
                    <link rel="canonical" href={"https://" + window.location.host + "/"}/>
                    <link rel="preconnect" href={URL_API()}/>
                    <meta property="og:url"                content="https://blog.terra-nostra.xyz"/>
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content="Terra Nostra votre partenaire pour vous accompagner sur les thématiques Emploi, recrutement, formation, travail temporaire, portage salarial & mobilité professionnelle et Conseil & entreprenariat, des professionnels à votre écoute." />
                    <meta property="og:description"        content="Quelle que soit votre question ou simplement une envie d'information ce blog traite de l'ensemble des thématiques RH et des sujets environnants carrière, formation, vie en entreprise, mobilité et bien plus encore." />
                    <meta property="og:image"              content="https://blog.terra-nostra.xyz/static/media/logo-entete-tn.112028e8.svg" />
                </Helmet>
                <div className="accueil">
                    <Header/>

                    <Container className="mt-5">
                        <Row className="justify-content-center">
                            <Col ld={12}>
                                {firstArticle.length > 0 ? firstArticle : <Loading setting="main" nb="1" header={true} grand={true}/>}
                            </Col>
                            <Col lg={5}>
                                {tabArticles.length > 0 ? tabArticles : <Loading setting="small" nb="2" header={true}/>}
                            </Col>
                        </Row>

                        <div className="justify-content-center mb-5">
                            <h1 className="text-left font-weight-bold"> Le blog Terra Nostra </h1>
                            {/*<Row className="justify-content-center mt-4">*/}
                            {/*    <Col lg={6} className="mb-4 mb-lg-0">*/}
                            {/*        <select className="custom-select" value={this.state.filterTags} onChange={this.handleChangeTags}>*/}
                            {/*            <option value="">Trier par sujet</option>*/}
                            {/*            {this.state.tags.map(tag => {*/}
                            {/*                return <option value={tag.id}>{tag.title}</option>*/}
                            {/*            })}*/}
                            {/*        </select>*/}
                            {/*    </Col>*/}
                            {/*    <Col lg={6}>*/}
                            {/*        <select className="custom-select" value={this.state.filterCategories} onChange={this.handleChangeCategories}>*/}
                            {/*            <option value="">trier par dossiers</option>*/}
                            {/*            {this.state.categories.map(category => {*/}
                            {/*                return <option value={category.id}>{category.title}</option>*/}
                            {/*            })}*/}
                            {/*        </select>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </div>

                        <Row>
                            { resArticle.length > 0 ? resArticle : <Loading setting="small" nb="6"/> }
                        </Row>
                        <AfficherPlus
                            onClick={(articles) => this.getAfficherPlus(articles)}
                            countArticles={this.state.countArticles}
                            options='limit:6, offset:'
                            index={this.state.index}
                            nbPlus={3}
                        />

                    {/*    <div className="mt-5 mb-5 justify-content-center">*/}
                    {/*        <Carousel>*/}
                    {/*            <Carousel.Item>*/}
                    {/*                <img*/}
                    {/*                    className="d-block w-100"*/}
                    {/*                    src="covid.png"*/}
                    {/*                    alt="First slide"*/}
                    {/*                />*/}
                    {/*                <Carousel.Caption>*/}
                    {/*                    <h3>First slide label</h3>*/}
                    {/*                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                    {/*                </Carousel.Caption>*/}
                    {/*            </Carousel.Item>*/}
                    {/*            <Carousel.Item>*/}
                    {/*                <img*/}
                    {/*                    className="d-block w-100"*/}
                    {/*                    src="covid.png"*/}
                    {/*                    alt="Third slide"*/}
                    {/*                />*/}

                    {/*                <Carousel.Caption>*/}
                    {/*                    <h3>Second slide label</h3>*/}
                    {/*                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                    {/*                </Carousel.Caption>*/}
                    {/*            </Carousel.Item>*/}
                    {/*            <Carousel.Item>*/}
                    {/*                <img*/}
                    {/*                    className="d-block w-100"*/}
                    {/*                    src="covid.png"*/}
                    {/*                    alt="Third slide"*/}
                    {/*                />*/}

                    {/*                <Carousel.Caption>*/}
                    {/*                    <h3>Third slide label</h3>*/}
                    {/*                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                    {/*                </Carousel.Caption>*/}
                    {/*            </Carousel.Item>*/}
                    {/*        </Carousel>*/}
                    {/*    </div>*/}
                    </Container>
                    {/*<Container className="bg-light p-5 text-center">*/}
                    {/*    <p className="t-blue"><i className="fas fa-envelope-open fa-3x"></i></p>*/}
                    {/*    <h6>*/}
                    {/*        Ne perdez jamais le fil !<br/>*/}
                    {/*        Souscrivez à notre newsletter*/}
                    {/*    </h6>*/}

                    {/*        <Form>*/}
                    {/*            <Row className="justify-content-center mt-3">*/}
                    {/*                <Col xs={12} md={6} lg={5} xl={6} className="mr-0 mb-4 mb-lg-0">*/}
                    {/*                    <Form.Group controlId="formNewsletter">*/}
                    {/*                        <Form.Control as="select" aria-label="Catégorie" size="100%">*/}
                    {/*                            <option selected disabled hidden>Choisir ce que je reçois</option>*/}
                    {/*                            <option value="1">Dossiers</option>*/}
                    {/*                            <option value="2">Actus</option>*/}
                    {/*                        </Form.Control>*/}
                    {/*                    </Form.Group>*/}
                    {/*                </Col>*/}

                    {/*                <Col xs={12} md={6} lg={5} xl={6}>*/}
                    {/*                    <Form.Group>*/}
                    {/*                        <InputGroup controlId="formMail" >*/}
                    {/*                            <Form.Control type="email" placeholder="Adresse e-mail" aria-label="Adresse Email" />*/}
                    {/*                            <InputGroup.Append>*/}
                    {/*                                <Button variant="custom-arrow-button">*/}
                    {/*                                    <FontAwesomeIcon icon={faArrowRight} />*/}
                    {/*                                </Button>*/}
                    {/*                            </InputGroup.Append>*/}
                    {/*                        </InputGroup>*/}
                    {/*                    </Form.Group>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Form>*/}
                    {/*</Container>*/}
                        <Newsletter />
                    <Footer/>
                </div>
            </>
        );
    }
}

export default Accueil;
