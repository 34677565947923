import React, {Component} from "react";
import {Helmet} from "react-helmet";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import GetQueryArticles from "./query/Articles";
import {API_HEAD, URL_API} from "./index";
import MinArticle from "./components/MinArticle";
import moment from "moment";
import {Container, Row} from "react-bootstrap";
import AfficherPlus from "./components/AfficherPlus";
import Loading from "./components/Loading";

class Resultats extends Component{

    state = {
        articles: [],
        countArticles: 0,
        index: 1,
        search: ""
    }

    async componentDidMount() {

        const params = this.getParams(window.location);
        const articles = await this.getArticles(params.query);

        this.setState(
            {
                articles: articles.entries,
                countArticles: articles.entryCount,
                search: params.query
            }
        )

    }

    async componentDidUpdate(prevProps) {

        const params = this.getParams(window.location);

        if (this.state.search !== params.query){
            const articles = await this.getArticles(params.query);

            this.setState(
                {
                    articles: articles.entries,
                    countArticles: articles.entryCount,
                    search: params.query,
                    index: 1
                }
            )
        }

    }

    getParams(location) {
        const searchParams = new URLSearchParams(location.search);
        return {
            query: searchParams.get('search') || '',
        };
    }

    async getArticles(title) {

        const options = `title: "*${title}*", limit:6`;

        return fetch(URL_API(), API_HEAD(GetQueryArticles(options, true)))
            .then(response => response.json())
            .then(data => data.data)
            ;
    }

    getAfficherPlus(articles){
        this.setState({
            articles: this.state.articles.concat(articles),
            index: this.state.index +1
        });
    }

    render() {

        const resArticle = this.state.articles.map (({blog, id, dateCreated, title, slug}) =>
            <MinArticle
                article={blog[0]}
                titre={title}
                date={moment (dateCreated).format ('DD/MM/YYYY')}
                id={id}
                slug={slug}
                key={id}
            />
        );

        return(
            <>
                <Helmet>
                    <script src="https://tarteaucitron.io/load.js?domain=blog.terra-nostra.xyz&uuid=54ba2930f284661c4346f50c813d2b6b062b092b"/>
                    <title>Blog Terra Nostra : resultats de votre recherche</title>
                    <link rel="canonical" href={"https://" + window.location.host + "/resultats"}/>
                    <link rel="preconnect" href={URL_API()}/>
                    <meta property="og:url"                content={`https://blog.terra-nostra.xyz/resultats?search=${this.search}`}/>
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content="Blog Terra Nostra : résultats de votre recherche" />
                    <meta property="og:description"        content="Consulez les résultats de votre recherche" />
                    <meta property="og:image"              content="https://blog.terra-nostra.xyz/static/media/plage.5770bbde.png" />
                </Helmet>
                <div className="accueil">
                    <Header/>

                    <Container className="mt-5">

                        <h4> Résultats pour la recherche : "{this.state.search}" </h4>

                        <Row className="mt-5">
                            {resArticle.length > 0 ? resArticle : <Loading setting="small" nb="6"/>}
                        </Row>
                        <AfficherPlus
                            onClick={(articles) => this.getAfficherPlus(articles)}
                            countArticles={this.state.countArticles}
                            index={this.state.index}
                            options={`title: "*${this.state.search}*", limit:6, offset:`}
                            nbPlus={0}
                        />
                    </Container>

                    <Footer/>
                </div>
            </>
        )
    }
}

export default Resultats