import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {reducer as form} from 'redux-form';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ConnectedRouter, connectRouter, routerMiddleware} from 'connected-react-router';

import './scss/main.scss';
import 'bootstrap/scss/bootstrap.scss';
import '@fortawesome/fontawesome-free/css/all.css';

import * as serviceWorker from './serviceWorker';
import Accueil from './Accueil';
import Apropos from "./Apropos";
import Faq from "./Faq";
import Contact from "./Contact";
import Article from "./Article";
import MentionsLegales from "./MentionsLegales"
import PolitiqueDeConfidentialite from "./PolitiqueDeConfidentialite";
import Resultats from "./Resultats";


const history = createBrowserHistory();
const store = createStore(
    combineReducers({
        router: connectRouter(history),
        form,
        /* Add your reducers here */
    }),
    applyMiddleware(routerMiddleware(history), thunk)
);

const tokenPreviewCraft = function () {
    let m = document.location.href.match(/\btoken=([^&]+)/);
    return m ? m[1] : '';
}

export const previewCraft = function () {
    return tokenPreviewCraft() !== '';
}

export const URL_API = function () {
    let token = tokenPreviewCraft();
    let url = process.env.REACT_APP_API_ENTRYPOINT;
    if (token !== '') {
        // Then forward that on whenever you are sending an API request
        url += `?token=${token}`;
    }

    return url;
}

export const API_HEAD = function (query) {
    return {
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TOKEN
        },
        body: JSON.stringify ({
            query: query
        })
    }
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Router>
                <Switch>
                    <Route path="/" component={Accueil} strict={true} exact={true}/>
                    <Route path="/qui-sommes-nous" component={Apropos} strict={true} exact={true}/>
                    <Route path="/faq" component={Faq} strict={true} exact={true}/>
                    <Route path="/contact" component={Contact} strict={true} exact={true}/>
                    <Route path="/article/:article" component={Article} strict={true} exact={true}/>
                    <Route path="/mentions-legales" component={MentionsLegales} strict={true} exact={true}/>
                    <Route path="/politique-de-confidentialite" component={PolitiqueDeConfidentialite} strict={true} exact={true}/>
                    <Route path="/resultats" component={Resultats} strict={true} exact={true}/>
                    {/* Add your routes here */}
                    <Route render={() => <h1>Not Found</h1>} />
                </Switch>
            </Router>
        </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();