import React from "react";
import {Row, Col, Image} from "react-bootstrap";
import LogoTerraNostra from "../img/TERRA_NOSTRA.svg";
import {Link} from "react-router-dom";

export default function Footer(){
    return (
        <footer className="bg-dark p-5">
            <div className="container text-white text-center">
                <h4>LE BLOG TERRA NOSTRA</h4>
                <Image src={LogoTerraNostra} alt="Terra nostra" style={{width: "25%"}}/>
                <Row className="justify-content-around pt-4 text-center">
                    <Col>
                        <Link to="/mentions-legales">Mentions légales</Link>
                    </Col>
                    <Col>
                        <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}