import React, {Component} from "react";
import {Modal, Row, InputGroup, FormControl, Container, Col} from "react-bootstrap";
import '../scss/components/_modal.scss';
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API_HEAD, URL_API} from "../index";
import Suggests from "./Suggests";

let timer;

class RechercheModal extends Component {

    state = {
        show: false,
        titles: "",
        entryCount: 0,
        search: ""
    }

    focusInput(id) {
        if (this.state.show === true) {
            document.getElementById(id).focus();
        }
    }

    setShow(val) {
        this.setState(
            {
                show: val,
                titles: ""
            }
        )
    }

    getQuery(title, options) {
        return `
            {
                entries (type: "articles", ${title}, ${options}) {
                    title
                    slug
                }
                entryCount(type: "articles", ${title})
            }
        `;
    }

    time(search) {

        clearTimeout(timer);

        this.setState(
            {
                search: search
            }
        )

        if (search.length >= 3) {
            timer = setTimeout(() => {
                this.getResearch(search)
            }, 500)
        } else {
            this.setState(
                {
                    titles: "",
                    entryCount: 0
                }
            )
        }
    }

    async getResearch(search) {

        const options = 'limit:3';
        const title = `title: "*${search.trim()}*"`;

        return fetch(URL_API(), API_HEAD(this.getQuery(title, options)))
            .then(response => response.json())
            .then(data => this.displayRes(data.data))
            ;

    }

    async displayRes(search) {
        let titres = []

        for (let i = 0; i < search.entries.length; i++) {
            titres.push({title: search.entries[i].title, slug: search.entries[i].slug})
        }

        this.setState(
            {
                titles: titres,
                entryCount: search.entryCount
            }
        )
    }

    onModalChange() {
        this.setShow(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.focusInput('searchInput')
    }

    render() {

        return (
            <>
                <div className="pt-3" onClick={() => this.setShow(true)}>
                    <FontAwesomeIcon icon={faSearch} className="text-white"/>
                </div>
                <Modal show={this.state.show} onHide={() => this.setShow(false)}>
                    <div className="mr-lg-3">
                        <Container>
                            <Row>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Rechercher"
                                        aria-label="Rechercher"
                                        aria-describedby="basic-addon2"
                                        id="searchInput"
                                        onChange={e => this.time(e.target.value.trim())}
                                        autoComplete="off"
                                    />
                                    {/*<InputGroup.Append>
                                    <Link to={"/resultats?search=" + this.state.search}>
                                        <Button className="border-0 pl-4 pr-4 bg-blue" type="button">
                                            <span><FontAwesomeIcon icon={faArrowRight} className="fa-2x text-white"/></span>
                                        </Button>
                                    </Link>
                                </InputGroup.Append>*/}
                                </InputGroup>
                                <Col className="col-12 p-0" id="resultat">
                                    <Suggests
                                        titres={this.state.titles}
                                        entryCount={this.state.entryCount}
                                        search={this.state.search}
                                        modal={() => this.onModalChange()}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal>
            </>
        )
    }
}

export default RechercheModal
