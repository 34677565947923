import React from "react";
import parse from "html-react-parser/index";
import DOMPurify from "dompurify";

class TexteDeuxColonnes extends React.Component {
    render() {
        const style = {
            columnCount: 2
        }

        return (
            <>
                <h2>{parse(DOMPurify.sanitize(this.props.titre))}</h2>
                <div className="text-justify" style={style}>{parse(DOMPurify.sanitize(this.props.texte))}</div>
            </>
        );
    }
}

export default TexteDeuxColonnes;