import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Newsletter from "./components/Newsletter";

class Faq extends Component{

    render() {

        return (
            <div className="accueil">
                <Header/>
                <div className="container-fluid bg-basique">
                    <div className="row justify-content-center text-left">
                        <div className="col-8 text-left">
                            <h2 className="text-white pt-5 mt-1 mt-md-5">Foire aux questions</h2>
                        </div>
                    </div>
                </div>

                <div className="container mb-5 mt-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 mb-5">
                            <h3><strong> Posez vos questions </strong></h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>

                            <h5> Votre question </h5>
                            <Form>
                                <div className="row text-left">
                                    <div className="col-12">
                                        <select className="custom-select mb-3 mt-3">
                                            <option> Sujet </option>
                                        </select>
                                        <textarea className="col-12 mt-3 mb-3 form-control" rows="5" placeholder="Message"/>
                                        <div className="form-group row justify-content-around justify-content-center">
                                            <div className="col-6">
                                                <label>Nom</label>
                                                <input type="text" className="form-control pt-3 pb-3" placeholder="Nom" />
                                            </div>
                                            <div className="col-6">
                                                <label>Prénom</label>
                                                <input type="email" className="form-control pt-3 pb-3" placeholder="Prénom" />
                                            </div>
                                        </div>
                                        <Form.Group controlId="formFaq">
                                            <Form.Label>Adresse e-mail</Form.Label>
                                            <Form.Control type="email" placeholder="Adresse e-mail" />
                                        </Form.Group>
                                        <div className="row justify-content-center">
                                            <button type="submit" className="bg-blue border-0 text-white p-2 mt-2 col-6">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>

                            <h3 className="mt-5"><strong>Chercher une question</strong></h3>
                            <div className="row text-left">
                                <div className="col-12">
                                    <select className="custom-select mb-3 mt-3">
                                        <option>Sujet</option>
                                    </select>
                                    <div className="row justify-content-center">
                                        <button type="submit" className="bg-blue border-0 text-white p-2 mt-2 col-6">Rechercher</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-10 col-lg-4">
                            <Newsletter/>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }
}

export default Faq