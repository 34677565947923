import React, {Component} from "react";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons";
import "../scss/pages/_newsletter.scss";
import NewsletterModal from "./NewsletterModal";

class Newsletter extends Component {
    constructor(props) {
        super(props)
        this.state = {show: false}
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    state = {
        show: false
    }

    openModal() {
        this.setState({show: true})
    }
    closeModal() {
        this.setState({show: false})
    }

    render() {
        return (
            <div className="text-center pt-3 pb-3" id="newsletter-section">
                <Container onClick={this.openModal}>
                    <p>
                        <FontAwesomeIcon icon={faEnvelopeOpenText} className="letter-open-icon display-3"/>
                    </p>
                    <p>
                        <strong>Ne perdez jamais le fil !</strong>
                    </p>
                    <p>
                        <strong>Souscrivez à notre newsletter</strong>
                    </p>
                </Container>
                <NewsletterModal
                    show={this.state.show}
                    closeHandler={this.closeModal}
                />
            </div>
        );
    }
}

export default Newsletter;