import React from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

class Texte extends React.Component {
    render() {
        return (
            <div className="text-justify">{parse(DOMPurify.sanitize(this.props.content))}</div>
        );
    }
}

export default Texte;