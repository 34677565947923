import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Row, Col, Image} from "react-bootstrap";
import LazyLoad from "react-lazy-load";

class MinArticle extends Component {

    getCategorie() {
        const categorie = this.props.article.categorie[0];
        if (categorie) {
            return (
                <Col>
                    <div>
                        <Image className="mr-2" src={categorie.picto[0].url} alt={categorie.picto[0].title} width="20" height="20"/>
                        <span>{categorie.title}</span>
                    </div>
                </Col>
            );
        }
    }

    getSujet() {
        const sujet = this.props.article.tag[0];
        if (sujet) {
            const sujetStyle = {
                backgroundColor: sujet.couleurs
            }
            return (
                <Col xs="auto">
                    <span className="pt-2 pb-2 pr-3 pl-3 shadow mr-2" style={sujetStyle}> {sujet.title} </span>
                </Col>
            );
        }
    }

    displayArticleCol() {
        let divStyle = {};
        const img = this.props.article.image;
        if (img) {
            divStyle = {
                backgroundImage: 'url(' + img[0].url + ')',
            }
        }
        let divTaille = {
            height: '214px',
        }
        if (this.props.grand) {
            divTaille = {
                height: '450px',
            }
        }
        const style = {...divStyle, ...divTaille};
        const linearGradient = {
            backgroundImage: 'linear-gradient(0, black, transparent)',
            width: '100%'
        };

        return (
            <LazyLoad>
                <div className="news-card p-3 bg-article shadow text-white pt-a" style={style}>
                    <div className="justify-content-center title-card" style={linearGradient}>
                        <Row className="mb-3 sujet">
                            {this.getSujet()}
                            {this.getCategorie()}
                        </Row>
                        <h2> {this.props.titre} </h2>
                        <p className="small text-light"> {this.props.date} </p>
                    </div>
                </div>
            </LazyLoad>
        )
    }


    render() {
        const url = "/article/" + this.props.slug;

        return (
            <div className={this.props.header ? 'mb-4' : 'mb-4 col-12 col-md-6'}>
                <Link to={url}>
                    {this.displayArticleCol()}
                </Link>
            </div>
        );
    }
}

export default MinArticle