import React from "react";
import {Row, Col, Image} from "react-bootstrap";
import parse from "html-react-parser/index";
import DOMPurify from "dompurify";
import LazyLoad from "react-lazy-load";

class Exergue extends React.Component {
    getImage() {
        if(this.props.img.length) {
            return (
                <Col md={4}>
                    <LazyLoad>
                        <Image src={this.props.img[0]?.url} alt={this.props.img[0]?.title} fluid />
                    </LazyLoad>
                </Col>
            );
        }
    }

    getTaille() {
        if(this.props.img.length) {
            return this.props.img.length ? 8 : 12
        }
    }

    render() {
        return (
            <Row>
                <Col md={this.getTaille()} className="text-break my-auto exergue-text">
                    {parse(DOMPurify.sanitize(this.props.texte))}
                </Col>

                {this.getImage()}
            </Row>
        );
    }
}

export default Exergue;