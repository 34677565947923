import React, { Component } from 'react';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import './scss/pages/_about.scss';
import {Container, Row, Col} from "react-bootstrap";
import {Helmet} from "react-helmet";


class PolitiqueDeConfidentialite extends Component{

    render() {

        return(
            <>
                <Helmet>
                    <title>Blog Terra Nostra : politique de confidentialité</title>
                    <link rel="canonical" href={"https://" + window.location.host + "/politique-de-confidentialite"}/>
                    <meta property="og:url"                content="https://blog.terra-nostra.xyz/politique-de-confidentialite"/>
                    <meta property="og:type"               content="article" />
                    <meta property="og:title"              content="Blog Terra Nostra : politique de confidentialité" />
                    <meta property="og:description"        content="Consultez la politique de confidentialité du blog Terra Nostra!" />
                    <meta property="og:image"              content="https://blog.terra-nostra.xyz//static/media/plage.5770bbde.png" />
                </Helmet>
                <div className="accueil">
                    <Header/>
                    <div className="article-header bg-basique">
                        <Container className="align-items-end">
                            <h1 className="text-white mt-1">Politique de confidentialité</h1>
                        </Container>
                    </div>

                    <Container className="mb-5 mt-5">
                        <Row className="row justify-content-center">
                            <Col xs={12}>
                                <h2>Politique de confidentialité des données personnelles</h2>

                                <p>Le Groupe MARE NOSTRUM attache une grande importance à la protection et la confidentialité de la vie privée de ses collaborateurs, stagiaires, candidats, intérimaires, clients et fournisseurs/prestataires externes et plus particulièrement de leurs données à caractère personnel.</p>

                                <p>Dans ce cadre, la présente Politique de confidentialité vous permettra de comprendre quelles données à caractère personnel nous collectons et ce que nous en faisons, ainsi que la façon dont vous pouvez protéger votre vie privée dans le cadre des prestations que nous fournissons.</p>

                                <br/>

                                <h3>1. QUEL TYPE DE DONNEES GROUPE MARE NOSTRUM COLLECTE-T-ELLE VOUS CONCERNANT ?</h3>

                                <p>Dans le cadre de nos prestations et pendant la fourniture de ces services, GROUPE MARE NOSTRUM collecte les catégories de données suivantes :</p>

                                <p>-          Données d'identification</p>

                                <p>-          Données de facturation et/ou de paiement</p>

                                <p>-          Données d'usage</p>

                                <p>-          Données de suivi de la relation interne avec le personnel</p>

                                <p>-          Données de sûreté</p>

                                <p>-          Données de géolocalisation</p>

                                <br/>

                                <p>L'ensemble de ces données appartiennent à la société et peuvent être récoltées via des logiciels sous licence.</p>

                                <br/>

                                <h3>2. DE QUELLE FA&Ccedil;ON GROUPE MARE NOSTRUM UTILISE-T-ELLE VOS DONNEES ?</h3>

                                <p><strong>2.1- Données informatiques</strong></p>

                                <p>Le Groupe MARE NOSTRUM utilise vos données pour les besoins de l'exécution et de la gestion du contrat, et/ou d'une convention. Ceci comprend les phases de la procédure de traitement et d'accomplissement du support contractuel précité, par exemple, et sans que cela soit exhaustif : la gestion de la relation contractuelle, la mise en poste, la fourniture d'équipement de protection individuel et la gestion de la prestation souscrite, la délivrance d'une information et le traitement de vos demandes, la facturation de la prestation, la gestion des réclamations et différends et les procédures de recouvrement, etc.</p>

                                <p>Ces données sont conserv&ées pendant le temps nécessaire à la gestion du contrat et/ou pendant les durées légales et probatoires imposées par d'autres réglementation que celle liée à la protection des données personnelles. Le Groupe MARE NOSTRUM pourra notamment utiliser vos données afin de respecter ses obligations légales (incluant les lois anti-fraude, les lois sur le blanchiment d'argent, les conditions générales de vente des revendeurs de logiciels et les dispositions concernant le retard ou l'absence de paiement par le client) et/ou pour répondre aux demandes des autorités publiques et gouvernementales.</p>

                                <p>Sans ces données, le Groupe MARE NOSTRUM ne pourrait pas exécuter ses prestations.</p>

                                <p>Le Groupe MARE NOSTRUM pourra également utiliser vos données lorsqu'il y a un intérêt légitime.</p>

                                <p>Vos données permettront ainsi au Groupe MARE NOSTRUM d'évaluer et d'améliorer ses prestations, ainsi que pour réaliser des actions de fidélisation, de prospection, de sondage et de promotion.</p>

                                <p>Avec votre accord exprès, le Groupe MARE NOSTRUM pourra vous communiquer par courrier électronique des informations commerciales pour des prestations non analogues à ceux déjà fournis ou, transmettre à des entités commerciales votre adresse électronique à des fins de prospection directe.</p>

                                <br/>

                                <p><strong>2.2- Alarme et accès par badge</strong></p>

                                <p>Afin de contrôler l'accès à certains de ses locaux, le Groupe MARE NOSTRUM peut munir son personnel d'un code d'alarme et/ou d'un badge d'accès pouvant être personnalisé quant aux jours et aux heures d'entrées.</p>

                                <p>Le badge personnalisable ne permet d'enregistrer que les heures d'entrées. Les codes (ou badges) d'alarme enregistrent indirectement les heures d'activation et désactivation.</p>

                                <p>Les données enregistrées dans ces dispositifs d'accès aux locaux d'alarme sont conservées pendant trois mois et sont uniquement accessibles au personnel, et aux sous-traitants, habilités à la sûreté des sites.</p>

                                <br/>

                                <p><strong>2.3- Vidéosurveillance</strong></p>

                                <p>Le Groupe MARE NOSTRUM a placé une partie de ses différents locaux sous vidéosurveillance afin d'assurer la sécurité de son personnel et de ses biens. Les images enregistrées dans ce dispositif ne sont pas utilisées à des fins de surveillance du personnel ni de contrôle des horaires.</p>

                                <p>La base légale du traitement est l'intérêt légitime (cf. article 6.1.f) du Règlement européen sur la protection des données.</p>

                                <p>Le personnel du Groupe MARE NOSTRUM est filmé par le dispositif. Les visiteurs occasionnels des locaux de la société sont également susceptibles d'être filmés.</p>

                                <br/>

                                <p>Les images peuvent être visionnées, en cas d'incident, par le personnel habilité du Groupe MARE NOSTRUM (personnel en charge de la maintenance du bâtiment et de la sûreté des sites, et sous-traitants sûreté) et par les forces de l'ordre. Les personnels de la société en charge de la maintenance du maté;riel peuvent également accéder aux images, à cette seule fin.</p>

                                <p>Les images sont conservées un mois.</p>

                                <p>En cas d'incident lié à la sécurité des personnes et des biens, les images de vidéosurveillance peuvent néanmoins être extraites du dispositif. Elles sont alors conservées sur un autre support le temps du èglement des procédures liées à cet incident et accessibles aux seules personnes habilitées dans ce cadre.</p>

                                <br/>

                                <p><strong>2.4 Géolocalisation</strong></p>

                                <p>Le système n'a pas pour objet le suivi du temps de travail des salaries. La mise en place du système de géolocalisation se fonde sur la sûreté et/ou la sécurité du salarié lui-même ou du véhicule dont il a la charge.</p>

                                <br/>

                                <p>Ces systèmes de géolocalisation embarques peuvent egalement être présents dans les véhicules fournis par les sociétés de location.</p>

                                <br/>

                                <h3>3. COMMENT POUVEZ-VOUS GERER LA COLLECTE ET L'UTILISATION QUI SONT FAITES DE VOS DONNEES ?</h3>

                                <p>Vous pouvez à tout moment demander d'accéder à vos données, les rectifier, les supprimer, vous opposer à un traitement pour des motifs légitimes en raison de votre situation particulière ou encore exercer votre droit à la portabilité de vos données, en adressant un courrier et en justifiant de votre identité. Pour nous assurer de cette dernière, merci de joindre une copie de votre pièce d'identité à votre demande. Nous ne serons pas en mesure de supprimer des données personnelles sans nous assurer de l'identité du demandeur qui demande la suppression de ses données.</p>

                                <p>A l'issue de la mise en oeuvre de votre demande par notre entreprise, la copie de votre carte d'identité sera détruite et aucun retour de celle-ci ne pourra être fait.</p>

                                <p>Les données dont le Groupe MARE NOSTRUM a besoin pour la finalité pour laquelle elles ont été collectées, nécessaires au respect d'une obligation légale et/ou à la constatation, à l'exercice ou à la défense de droits en justice pourront cependant ne pas être supprimées.</p>

                                <br/>

                                <p>En cas de réclamation à laquelle le Groupe MARE NOSTRUM ne vous aurait pas donné de réponse satisfaisante, vous pouvez directement vous adresser à la Commission Nationale de l'Informatique et des Libertés (CNIL) en charge du respect des obligations en matière de données personnelles.</p>

                                <br/>

                                <h3>4. DE QUELLE FA&Ccedil;ON GROUPE MARE NOSTRUM ASSURE-T-ELLE LA SECURITE DE VOS DONNEES ?</h3>

                                <p>Le Groupe MARE NOSTRUM met en oeuvre les actions nécessaires afin de protéger les données personnelles qu'elle traite. Vos données sont traitées de façon électronique et/ou manuelle et en tout état de cause, de façon que leur sécurité, protection et confidentialité soient assurées au regard de leur niveau de sensibilité, via des mesures administratives, organisationnelles, techniques et physiques pour prévenir la perte, le vol, l'utilisation, la divulgation ou encore la modification non-autorisées.</p>

                                <br/>

                                <h3>5. QUI PEUT AVOIR ACCES A VOS DONNEES PERSONNELLES ?</h3>

                                <p>Vos données personnelles sont traitées par le Groupe MARE NOSTRUM, les responsables des entités du groupe, de leurs permanents et de leurs sous-traitants, pour les besoins de la gestion et de l'exécution des prestations que vous avez demandées ou autorisées.</p>

                                <p>Sur demande, elles peuvent également être transférées aux personnes et autorités dont le droit d'accès aux données à caractère personnel est reconnu par la loi, les règlements ou les dispositions émanant d'autorités habilitées par la loi.</p>

                                <br/>

                                <h3>6. SITES INTERNET : COOKIES ET LIENS EXTERNES</h3>

                                <p>D'une façon générale, vous pouvez visiter notre site sans vous identifier, ni révéler des informations personnelles. Toutefois, certaines circonstances peuvent nous amener à vous demander des renseignements (sur ce site ou hors du site web).</p>

                                <p>Les informations personnelles comme vos civilité, nom, prénom, numéro de téléphone, email, adresse postale, ne sont retenues que lorsque vous nous les soumettez volontairement (directement ou par le biais d'un intermédiaire) ou dans le cadre d'une relation contractuelle (passée, en cours, ou à venir).</p>

                                <p><strong>Cas des Cookies</strong></p>

                                <p>Si vous déposez un commentaire ou répondez au formulaire de contact sur notre site, il vous sera proposé; d'enregistrer votre nom, adresse de messagerie et site web dans des cookies. C'est uniquement pour votre confort afin de ne pas avoir à saisir ces informations si vous visitez de nouveau notre site. Ces cookies expirent au bout d'un an.</p>

                                <p>En modifiant ou en publiant un article, un cookie supplémentaire sera enregistré dans votre navigateur. Ce cookie ne comprend aucune donnée personnelle. Il indique simplement l'identifiant de l'article que vous venez de modifier. Il expire au bout d'un jour.</p>

                                <br/>

                                <p><strong>Cas des Liens Externes</strong></p>

                                <p>Des liens vers d'autres sites web sont disponibles depuis notre site afin de vous proposer l'information la plus complète possible. Si vous utilisez ces liens, vous quittez notre site. Le Groupe MARE NOSTRUM n'a pas contrôlé; ces sites et ne les contrôlera pas, ces sites n'étant pas sous sa responsabilité. Le Groupe MARE NOSTRUM n'est, en aucun cas, responsable des contenus et/ou de la politique de confidentialité des données personnelles de ces autres sites.</p>

                                <p>Il ne cautionne ni n'approuve les contenus auxquels ces sites ou sources donnent accès et exclut toute responsabilité en ce qui concerne ces contenus. Si vous décidez d'accéder à ces sites, vous le faites sous votre entière responsabilité.</p>

                                <br/>

                                <h3>6. EVOLUTION DE LA POLITIQUE DE CONFIDENTIALITE</h3>

                                <p>La présente Politique de confidentialité peut être amenée à évoluer, notamment en raison des évolutions législatives et réglementaires.</p>

                                <br/>

                                <h3>7. COMMENT NOUS CONTACTER</h3>

                                <p>Vous pouvez nous contacter par courrier postal à l'adresse suivante :</p>

                                <p>MARE NOSTRUM<br />
                                    Délégué à la Protection des Données Personnelles<br />
                                    9 avenue de Constantine<br />
                                    38100 GRENOBLE.</p>

                            </Col>
                        </Row>
                    </Container>

                    <Footer/>
                </div>
            </>
        )
    }
}

export default PolitiqueDeConfidentialite