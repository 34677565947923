export default function GetQueryArticles(options, countOptions = false) {

    return `
            {
            entries(type: "articles", ${options}) {
                id
                title
                slug
                dateCreated
                ... on articles_articles_Entry {
                  blog {
                    ... on MatrixBlockInterface {
                      ... on blog_titre_BlockType {
                        image {
                          url
                        }
                        categorie {
                          ... on typeDeContenu_Category {
                            title
                            picto {
                              url
                              title
                            }
                          }
                        }
                        tag {
                          id
                          ... on sujets_Category {
                            title
                            couleurs
                          }
                        }
                        typeHandle
                      }
                    }
                  }
                }
              }
              entryCount (type: "articles", ${countOptions ? options : ''})
            }
        `;
}