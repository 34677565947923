import React from "react";

class Video extends React.Component {
    render() {
        return (
            <>
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    style={
                        {
                            width: '100%',
                            height: '315px'
                        }
                    }
                    src={this.props.video.url}
                    frameBorder={0}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </>
        );
    }
}

export default Video;