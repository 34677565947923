import React, {Component} from "react";
import {Link} from "react-router-dom";


class Suggests extends Component{

    displaySuggests(){
        if (this.props.titres === ""){
            return ("")
        } else {
            const res = this.props.titres.map(({title, slug}, index) => {
                const path = "/article/" +  slug
                return(
                    <div key={index}>
                        <Link className="text-dark" to={path} onClick={this.props.modal} >
                            <div className="bg-light pl-3 pr-3 survol">
                                <p className="text-truncate pt-4"> {title} </p>
                            </div>
                        </Link>
                    </div>
                    )
            })
            return res
        }
    }

    displayEntrys(){

        let dispMore
        let search = this.props.search.trim()

        if (this.props.titres === ""){
            dispMore = ("")
        } else {
            dispMore = (
                <div className="container">
                    <div className="row justify-content-center text-primary bg-light pt-3 pb-2 border-secondary border-top">
                        <Link to={"/resultats?search=" + search} onClick={this.props.modal}><p className="text-primary"> Voir tous les résultats ({this.props.entryCount})  </p></Link>
                    </div>
                </div>
            )
        }

        return dispMore

    }

    render() {


        return(
            <div className="research" id="res">
                {this.displaySuggests()}
                {this.displayEntrys()}
            </div>
        )
    }
}

export default Suggests