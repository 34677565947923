import React from "react";
import '../../scss/pages/_article.scss'
import {Container, Image} from "react-bootstrap";

class Titre extends React.Component {
    getCategorie() {
        if (this.props.cat) {
            return (
                <span><Image src={this.props.cat.picto[0].url} alt={this.props.cat.picto[0].title} width="25" height="25"/> {this.props.cat.title} </span>
            );
        }
    }

    getSujet() {
        if (this.props.sujet) {
            const sujetStyle = {
                backgroundColor: this.props.sujet.couleurs
            }
            return (
                <span className="pt-2 pb-2 pr-3 pl-3 shadow mr-2" style={sujetStyle}> {this.props.sujet.title} </span>
            );
        }
    }

    render() {
        const style = {
            backgroundImage: 'linear-gradient(0, black, transparent), url(' + this.props.img[0].url + ')',
        }
        return (
            <div className="article-header mb-4" style={style}>
                <Container className="align-items-end pb-1">
                    <p>
                        {this.getSujet()}
                        {this.getCategorie()}
                    </p>
                    <h1>{this.props.titre}</h1>
                    <p className="small">{this.props.date}</p>
                </Container>
            </div>
        );
    }
}

export default Titre;