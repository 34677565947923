import React from "react";
import {Col, Figure} from "react-bootstrap";

class Images extends React.Component {
    getImage(img) {
        return (
            <Col>
                <Figure>
                    <Figure.Image
                        src={img.url}
                    />
                    <Figure.Caption>
                        {img.title}
                    </Figure.Caption>
                </Figure>
            </Col>
        );
    }

    render() {
        return (
            <>
                {this.getImage(this.props.img1[0])}
                {this.props.img2.length > 0 && this.getImage(this.props.img2[0])}
                {this.props.img3.length > 0 && this.getImage(this.props.img3[0])}
            </>
        );
    }
}

export default Images;