import React from "react";
import parse from "html-react-parser/index";
import DOMPurify from "dompurify";
import {Row, Col, Image} from "react-bootstrap";
import LazyLoad from "react-lazy-load";

class TexteImage extends React.Component {
    render() {
        const image = (
            <Col xs={12} lg={this.props.disposition === "full" ? 12 : 4}>
                <LazyLoad>
                    <Image
                        width="100%"
                        className="mr-3"
                        src={this.props.img[0].url}
                        alt={this.props.img[0].title}
                    />
                </LazyLoad>
            </Col>
        );

        const content = (
            <Col xs={12} lg={this.props.disposition === "full" ? 12 : 8}>
                <h2>{this.props.titre}</h2>
                <div className="text-justify">{parse(DOMPurify.sanitize(this.props.texte))}</div>
            </Col>
        );

        return (
            <Row>
                {this.props.disposition === "right" ? content : image}
                {this.props.disposition === "right" ? image : content}
            </Row>
        );
    }
}

export default TexteImage;