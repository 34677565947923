import {Image, Button, Form, Modal} from "react-bootstrap";
import React, {Component} from "react";
import {API_HEAD, URL_API} from "../index";
import Alert from "react-bootstrap/Alert";
import logo from "../img/logo192.png";
import {Link} from "react-router-dom";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

class NewsletterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            categoriesSelected: [],
            email: '',
            nom: '',
            prenom: '',
            statutpro: '',
            register: false,
            categorieError: false,
            disclaimer: false,
        }

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleStatutProChange = this.handleStatutProChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeDisclaimer = this.handleChangeDisclaimer.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show !== prevProps.show && !this.state.categories.length) {
            this.getCategories();
        }
    }

    getCategoriesSelectedString() {
        let categories = "";
        this.state.categoriesSelected.forEach((key, value) => { categories += value + ',' });
        return categories.substring(0, categories.length - 1);
    }

    sendNewsletter = (event) => {
        event.preventDefault();

        if (!this.state.disclaimer) {
            return;
        }

        if(this.state.categoriesSelected.size === 0) {
            this.setState({ categorieError: true });
            return;
        }

        const query = `
            mutation{
                save_newsletterTerraNostra_newsletterTerraNostra_Entry(email: "${this.state.email}", prenom: "${this.state.prenom}", nom: "${this.state.nom}", categories: "${this.state.categoriesSelected.join(', ')}", statutpro: "${this.state.statutPro ? this.state.statutPro : 'particulier'}", authorId: 1) {
                    email,
                    authorId,
                    prenom,
                    categories
                    nom,
                    statutpro
                }
            }
        `;

        fetch(URL_API(), API_HEAD(query))
            .then(response => response.json())
            .then(data => {
                if(!data['errors']) {
                    this.setState({register: true});
                }

            })
        ;
    }

    getCategoriesQuery(options) {
        return `
            {
                categories(groupId: "2") {
                  id
                  title
                }
            }
        `;
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value})
    }

    handleNameChange(event) {
        this.setState({ nom: event.target.value});
    }

    handleFirstNameChange(event) {
        this.setState( { prenom: event.target.value});
    }

    handleStatutProChange(event) {
        this.setState( { statutPro: event.target.value});
    }

    handleCategoryChange(event) {
        this.setState({
            categoriesSelected: event
        });
    }

    handleChangeDisclaimer(event) {
        this.setState({ disclaimer: event.target.checked});
    }

    handleClose() {
        this.props.closeHandler()
    }

    getCategories() {
        const options = '';
        fetch(URL_API(), API_HEAD(this.getCategoriesQuery(options)))
            .then(response => response.json())
            .then(data => {
                const categories = [];
                data.data.categories.map((cat) => {
                    return categories.push({key: cat.title, label: cat.title});
                });

                this.setState({categories: categories});
            })
        ;
    }

    render() {
        const {show} = this.props;
        return <Modal
            onHide={this.handleClose}
            size="lg"
            centered={true}
            show={show}
            dialogClassName="newsletterModal">
            { !this.state.register && <Modal.Body>
                <div className="text-center">
                    <Image width="100" heigth="100" src={logo} alt="maya"/>
                    <h4>Restez informés des dernières nouveautés pour vos projets de vie !</h4>
                </div>
                <h2>Maya, votre contact Terra Nostra, s'occupe de dénicher l'actualité qui vous intéresse</h2>

                <Form onSubmit={this.sendNewsletter}>
                    <div className="mt-2 mb-2">
                        <span className="required">*</span>
                        <Form.Control
                            required="required"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            placeholder="Adresse e-mail"
                            aria-label="Adresse Email"
                        />
                    </div>

                    <div className="mt-2 mb-2">
                        <span className="required">*</span>
                        <Form.Control
                            required="required"
                            type="text"
                            value={this.state.nom}
                            onChange={this.handleNameChange}
                            placeholder="Nom de famille"
                            aria-label="Nom de famille"
                        />
                    </div>

                    <div className="mt-2 mb-2">
                        <span className="required">*</span>
                        <Form.Control
                            required="required"
                            type="text"
                            value={this.state.prenom}
                            onChange={this.handleFirstNameChange}
                            placeholder="Prénom"
                            aria-label="Prénom"
                        />
                    </div>

                    <div className="mt-2">
                        <div>Je suis un :</div>
                        <Form.Control
                            as="select"
                            onChange={this.handleStatutProChange}
                        >
                            <option>--- Sélectionner votre statut ---</option>
                            <option value="particulier">Particulier</option>
                            <option value="professionnel">Professionnel</option>
                        </Form.Control>
                    </div>


                    <div className="mt-3">
                        <div>Je choisis les catégories qui m'intéresse :</div>
                        { this.state.categories.length > 0 &&
                            <>
                                <span className="required">*</span>
                                <DropdownMultiselect
                                    options={this.state.categories}
                                    name="categories"
                                    handleOnChange={(selected) => this.handleCategoryChange(selected)}
                                    placeholder="--- Sélectionner minimum une catégorie ---"
                                    className="form-control bg-white"
                                />
                            </>
                        }
                        { this.state.categorieError &&
                            <Alert variant="danger">
                                Vous devez sélectionner au minimum une catégorie.
                            </Alert>
                        }
                    </div>
                    <p className="text-danger mt-4">* Champs obligatoire</p>
                    <div className="font-italic text-justify mb-2">
                        <Form.Check
                            id="disclaimer"
                            type="checkbox"
                            name="disclaimer"
                            required={true}
                            value={this.state.disclaimer}
                            onChange={this.handleChangeDisclaimer}
                            className="d-inline-block"
                        />
                       <small>
                           En rensaignant votre adresse email, vous acceptez de recevoir nos newsletters par courier electronique et vous prenez connaissance de notre <Link className="text-primary" target="_blank" to='/politique-de-confidentialite'>Politique de confidentialité</Link>
                       </small>
                    </div>
                    <Button className="bg-blue text-white border-0 text-center btn-block" type="submit">
                        Valider
                    </Button>
                </Form>
            </Modal.Body> }
            { this.state.register && <Modal.Body>
                <h4 className="text-center m-3">Inscription réussie</h4>
                <Button className="bg-blue text-white border-0 text-center btn-block" onClick={this.handleClose}>
                    Valider
                </Button>
            </Modal.Body> }
        </Modal>
    }
}

export default NewsletterModal