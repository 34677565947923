import React, { Component } from 'react';
import './scss/pages/_about.scss';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import {API_HEAD, URL_API} from "./index";
import Titre from "./components/Bloc/Titre";
import Texte from "./components/Bloc/Texte";
import TexteImage from "./components/Bloc/TexteImage";
import TexteDeuxColonnes from "./components/Bloc/TexteDeuxColonnes";
import Images from "./components/Bloc/Images";
import Exergue from "./components/Bloc/Exergue";
import Video from "./components/Bloc/Video";
import moment from "moment";
import {Helmet} from "react-helmet";
import Newsletter from "./components/Newsletter";
import {Container, Row, Col} from "react-bootstrap";
import ArticleLoading from "./components/ArticleLoading";

class Article extends Component{
    // necessaire pour eviter le message d'erreur "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method."
    _isMounted = false;

    constructor(props) {
        super (props);
        this.state = {
            article: null,
            params: ''
        }
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getArticleContent();
        this.setState({
            params: this.props.match.params.article,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.article !== this.state.params) {
            this.getArticleContent();
            this.setState({
                params: this.props.match.params.article,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getArticleContent() {
        const query = `{
            entry(slug: "${this.props.match.params.article}") {
                id
                title
                slug
                dateCreated
                ... on articles_articles_Entry {
                  blog {
                    ... on MatrixBlockInterface {
                      ... on blog_titre_BlockType {
                        image {
                          url
                        }
                        categorie {
                          ... on typeDeContenu_Category {
                            title
                            picto {
                              url
                              title
                            }
                          }
                        }
                        tag {
                          id
                          ... on sujets_Category {
                            title
                            couleurs
                          }
                        }
                        seo {
                          title
                          description
                        }
                        typeHandle
                      }
                      ... on blog_texteSimple_BlockType {
                        titre
                        texte
                        typeHandle
                      }
                      ... on blog_texteImage_BlockType {
                        images {
                          url
                          title
                        }
                        disposition
                        texte
                        typeHandle
                      }
                      ... on blog_texteDeuxColonnes_BlockType {
                        titre
                        texte
                        typeHandle
                      }
                      ... on blog_imagesMax3_BlockType {
                        image1 {
                          url
                          title
                        }
                        image2 {
                          url
                          title
                        }
                        image3 {
                          url
                          title
                        }
                        typeHandle
                      }
                      ... on blog_exergueImage_BlockType {
                        exergue
                        image {
                          url
                          title
                        }
                        typeHandle
                      }
                      ... on blog_video_BlockType {
                        video {
                          url
                        }
                        typeHandle
                      }
                    }
                  }
                }
            }
        }`;

        fetch(URL_API(), API_HEAD(query))
            .then(response => response.json())
            .then(data => {
                if (this._isMounted) {
                    this.setState({
                        article: data.data.entry
                    });
                }
            })
        ;
    }

    getContent() {
        let article = this.state.article;
        let content = '';
        let titre = '';
        if (article) {
            // eslint-disable-next-line array-callback-return
            content = article.blog.map(function(value, index) {
                // eslint-disable-next-line default-case
                switch (value.typeHandle) {
                    case "titre":
                        titre = <Titre
                            img={value.image}
                            titre={article.title}
                            date={moment(article.dateCreated).format('DD/MM/YYYY')}
                            cat={value.categorie[0]}
                            sujet={value.tag[0]}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                    case "texteSimple":
                        return <Texte
                            titre={value.titre}
                            content={value.texte}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                    case "texteImage":
                        return <TexteImage
                            img={value.images}
                            titre={value.titre}
                            texte={value.texte}
                            disposition={value.disposition}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                    case "texteDeuxColonnes":
                        return <TexteDeuxColonnes
                            titre={value.titre}
                            texte={value.texte}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                    case "imagesMax3":
                        return <Images
                            img1={value.image1}
                            img2={value.image2}
                            img3={value.image3}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                    case "exergueImage":
                        return <Exergue
                            img={value.image}
                            texte={value.exergue}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                    case "video":
                        return <Video
                            video={value.video}
                            key={index}
                        />
                        // eslint-disable-next-line no-unreachable
                        break;
                }
            });
        }

        return (
            <>
                {this.state.article ? titre : <ArticleLoading banner="true"/>}

                <Container>
                    <Row>
                        <Col md={8}>
                            {this.state.article ? content : <ArticleLoading/>}
                        </Col>
                        <Col md={4}>
                            <Newsletter/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }


    render() {
        const content = this.getContent();
        const {article} = this.state;
        return(
            <>
                {article && article.blog[0].seo ?
                    <Helmet>
                        <script src="https://tarteaucitron.io/load.js?domain=blog.terra-nostra.xyz&uuid=54ba2930f284661c4346f50c813d2b6b062b092b"/>
                        <title>{article.blog[0].seo.title}</title>
                        <meta name="description" content={article.blog[0].seo.description}/>
                        <link rel="canonical" href={"https://" + window.location.host + "/article/" + article.slug}/>
                        <link rel="preconnect" href={URL_API()}/>
                        <meta property="og:url"                content={`https://${window.location.host}/${article.slug}`}/>
                        <meta property="og:type"               content="article" />
                        <meta property="og:title"              content={article.blog[0].seo.title} />
                        <meta property="og:description"        content={article.blog[0].seo.description} />
                        <meta property="og:image"              content={article.blog[0].image[0].url} />
                    </Helmet> : ''}

                <div className="accueil">
                    <Header/>
                    {content}
                    <Footer/>
                </div>
            </>
        )
    }
}

export default Article;