import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {API_HEAD, URL_API} from "../index";
import GetQueryArticles from "../query/Articles";

class AfficherPlus extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    handleMore(){
        this.setState({
            loading: true,
        });

        const options = this.props.options + (this.props.index * 6 + this.props.nbPlus);

        fetch(URL_API(), API_HEAD(GetQueryArticles(options)))
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                });
                this.props.onClick(data.data.entries);
            })
        ;
    }

    afficherPlus() {
        if (this.props.countArticles > (this.props.index * 6 + this.props.nbPlus)) {
            return (
                <div className="row justify-content-center mt-4">
                    <Button
                        className="p-3 bg-blue text-white border-0 mb-4"
                        onClick={() => this.handleMore()}
                        disabled={this.state.loading}
                    >
                        <i className={ this.state.loading ? 'fas fas-redo-alt fa-spinner mr-2' : 'fas fa-redo-alt mr-2'}></i>
                        Afficher plus
                    </Button>
                </div>
            );
        }
    }

    render() {

        return(
            <>
                {this.afficherPlus()}
            </>
        )
    }
}

export default AfficherPlus