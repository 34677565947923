import React from 'react';
import ContentLoader from "react-content-loader";

class Loading extends React.Component {
    render() {
        let style = {
            width: '100%',
            height: this.props.grand ? '450px' : '214px'
        }

        var content = [];
        for (let i = 0; this.props.nb > i; i++) {
            content.push(
                <ContentLoader
                    speed={2}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    style={style}
                    className={this.props.header ? 'mb-4' : 'mb-4 col-12 col-md-6'}
                    key={i}
                >
                    <rect x="0" y="0" rx="2" ry="2" style={style}/>
                </ContentLoader>
            );
        }

        return (
            <>
                {content}
            </>
        )
    }
}

export default Loading;